<template>
  <div class="page-wrap">
    <div class="search-wrap">
      <Search
        v-model="state.search"
        placeholder="输入搜索词，多个词之间逗号分隔"
        @search="searchHandle"
      />
    </div>
    <div class="history-search">
      <div class="title">最近搜索</div>
      <div class="history-content">
        <div class="search-item" v-for="item in state.historyList" :key="item.id" @click="searchClickHandle(item)">{{ item.content }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Search } from 'vant'
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import { getHistoryRetrieval } from '@/api/'
export default {
  components: {
    Search
  },
  setup () {
    // document.title = '检索'
    const state = reactive({
      historyList: [],
      search: ''
    })
    const router = useRouter()

    getHistoryRetrieval({
      search_type: 'search'
    }).then(res => {
      console.log(res)
      if (res.code === 200) {
        state.historyList = res.data
      }
    })

    const searchClickHandle = (item) => {
      router.replace(`/searchList?search=${item.search_content}`)
    }

    const searchHandle = () => {
      router.replace(`/searchList?search=${state.search}`)
    }

    return {
      state,
      searchHandle,
      searchClickHandle
    }
  }
}
</script>

<style lang="less" scoped>
.page-wrap {
  width: 100%;
  height: 100%;
  background: #fff;
}
.search-wrap {
  &:deep(.van-search__content) {
    border: 1px solid #0C86FE;
    background: #fff;
    border-radius: 4px;
  }
  &:deep(.van-field__left-icon) {
    color: #0C86FE;
  }
}
.history-search {
  margin-top: 18px;
  padding: 0 16px;
  .title {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
  }
  .history-content {
    display: flex;
    flex-wrap: wrap;
    .search-item {
      margin-top: 16px;
      margin-right: 12px;
      padding: 4px 12px;
      background: #EBF5FF;
      border-radius: 14px;
      font-size: 14px;
      color: #333333;
      line-height: 20px;
    }
  }
}
</style>
